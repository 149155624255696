html {
  color: #000;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #78b1ff;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.bg-navbar {
  background-color: #00254c;
}

.container-fluid, .header-image {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.section-odd {
  background-color: #ececec;
}

.content {
  padding-top: 70px;
  padding-bottom: 70px;
}

h2 {
  color: #00254c;
  font-weight: bold;
}

.footer h2 {
  color: #fff;
}

.footer {
  color: #fff;
  min-height: 70px;
  background-color: #00254c;
  padding-top: 10px;
}

.footer a {
  color: #fff;
}

h4 {
  color: #00254c;
  padding-top: 20px;
  font-weight: bold;
}

h5 {
  color: #78b1ff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

body {
  padding-top: 70px;
  font-family: Montserrat, sans-serif;
  position: relative;
}

.headline {
  text-transform: uppercase;
}

.typewriter {
  position: absolute;
  top: 61.0236%;
  left: 15%;
  transform: translate(0%, -50%);
}

.typewriter h1 {
  font-weight: bold;
}

.typewriter span {
  white-space: nowrap;
  letter-spacing: .15em;
  border-right: .1em solid #000;
  margin: 0 auto;
  padding-right: 5px;
  animation: 3.5s steps(40, end) typing, .75s step-end infinite blink-caret;
  overflow: hidden;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: #0000;
  }

  50% {
    border-color: #000;
  }
}

.brackets {
  transform: translate(0%, -100%);
}

.bereich-text, .kundenprojekt {
  text-align: justify;
}

@media (min-width: 1100px) {
  .video_float {
    float: left;
    margin-bottom: 25px;
    margin-right: 35px;
  }

  .video_text_min {
    padding-left: 10px;
  }
}

@media (max-width: 1100px) {
  .video_text_max {
    padding-top: 25px;
  }
}

@media (min-width: 1310px) {
  .clearfix {
    flex-direction: column;
    display: flex;
  }

  .video_float {
    padding-top: 80px;
  }
}

@media (min-width: 1330px) {
  .clearfix {
    flex-direction: column;
    display: flex;
  }

  .video_float {
    padding-top: 50px;
  }
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("montserrat-v25-latin-regular.d65cd6e6.eot");
  src: local(""), url("montserrat-v25-latin-regular.d65cd6e6.eot#iefix") format("embedded-opentype"), url("montserrat-v25-latin-regular.ac08c953.woff2") format("woff2"), url("montserrat-v25-latin-regular.84e5cdd3.woff") format("woff"), url("montserrat-v25-latin-regular.9757cef3.ttf") format("truetype"), url("montserrat-v25-latin-regular.0a13900a.svg#Montserrat") format("svg");
}

.hexagon {
  width: 164.545px;
  height: 95px;
  color: #00254c;
  vertical-align: middle;
  background: #ececec;
  margin: 47.5px auto;
  line-height: 95px;
  position: relative;
}

.hexagon:before, .hexagon:after {
  content: "";
  border-left: 82.2724px solid #0000;
  border-right: 82.2724px solid #0000;
  position: absolute;
  left: 0;
}

.hexagon:before {
  border-bottom: 47.5px solid #ececec;
  bottom: 100%;
}

.hexagon:after {
  border-top: 47.5px solid #ececec;
  top: 100%;
}

.inside-hex {
  font-size: 95px;
}

/*# sourceMappingURL=impressum.8a946c45.css.map */
